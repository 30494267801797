<template>
  <el-container>
    <Header pageName="公告管理"></Header>
    <el-main>
      <el-button type="primary" size="small" @click="add">添加公告</el-button>
      <el-form class="el-form-search" label-width="70px">
        <el-form-item label="标题：">
          <el-input v-model="title" placeholder="请输入公告标题" size="small"></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="sort" label="排序" align="center"></el-table-column>
        <el-table-column prop="title" label="公告标题" align="center"></el-table-column>
        <el-table-column prop="create_time" label="发布时间" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
        <el-table-column prop="money" label="操作" align="center">
          <template v-slot="{ row }">
            <el-button @click="edit(row)" size="small" type="text">编辑</el-button>
            <el-button @click="del(row)" size="small" type="text">删除</el-button>
          </template>
        </el-table-column> 
      </el-table>
      <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
    <el-dialog title="添加/编辑公告" :visible.sync="is_showAdd" width="800px">
      <el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
        <el-form-item label="排序：">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="公告标题：" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="公告内容：" prop="content">
          <RichText :richTxt="addForm.content" :is_site="0" width="640" height="300" @soninfo="val => (addForm.content = val)"></RichText>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="is_showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="addNotice">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script> 
import Header from './components/header';
import RichText from '@/components/richText';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';  
export default {
  components: {
    Header,
    RichText,
    Paging,
  },
  data() {
    return {
      is_showAdd: !1,
      list: [],
      page: 1,
      rows: 10,
      total_number: 0,
      title: '',
      addForm: {
        title: '',
        content: '',
        sort: '',
        is_show: 1,
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入公告标题',
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: '请输入公告内容',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.page = 1;
      this.row = 10;
      this.title = '';
      this.getList();
    },
    add() {
      this.addForm = {
        title: '',
        content: '',
        sort: '',
        is_show: 1,
      };
      this.is_showAdd = !0;
    },
    edit(row) {
      this.addForm.title = row.title;
      this.addForm.content = row.content;
      this.addForm.sort = row.sort;
      this.is_showAdd = !0;
    },
    del(row) {
      this.$axios
        .post(this.$api.admin.articleDel, {
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      this.$axios
        .post(this.$api.admin.articleList, {
          page: this.page,
          rows: this.rows,
          title: this.title,
          type: 1,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addNotice() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.addForm.id ? this.$api.admin.articleEdit : this.$api.admin.articleAdd;
          if (!this.addForm.sort) this.addForm.sort = 99;
          this.addForm.type = 1;
          this.$axios.post(url, this.addForm).then(res => {
            if (res.code == 0) {
              this.is_showAdd = !1;
              this.getList();
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
  }
}
</style>